define("ember-keyboard/utils/is-key", ["exports", "ember-keyboard/utils/keyboard-listener", "ember-keyboard/utils/platform", "ember-keyboard/fixtures/key-maps", "ember-keyboard/fixtures/modifiers-array", "ember-keyboard/utils/get-mouse-name"], function (_exports, _keyboardListener, _platform, _keyMaps, _modifiersArray, _getMouseName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isKey;
  const ALL_SYMBOL = '_all';
  function isKey(listenerOrListenerName, event) {
    let platform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : (0, _platform.default)();
    let listener;
    if (listenerOrListenerName instanceof _keyboardListener.default) {
      listener = listenerOrListenerName;
    } else if (typeof listenerOrListenerName === 'string') {
      listener = _keyboardListener.default.parse(listenerOrListenerName, platform);
    } else {
      throw new Error('Expected a `string` or `KeyCombo` as `keyComboOrKeyComboString` argument to `isKey`');
    }
    if (listener.type !== event.type) {
      return false;
    }
    if (isAll(listener)) {
      return true;
    }
    if (modifiersMatch(listener, event) && (keyOrCodeMatches(listener, event) || mouseButtonMatches(listener, event))) {
      return true;
    }
    return specialCaseMatches(listener, event, platform);
  }
  function isAll(listener) {
    return listener.keyOrCode === ALL_SYMBOL && listener.altKey === false && listener.ctrlKey === false && listener.metaKey === false && listener.shiftKey === false;
  }
  function modifiersMatch(listener, keyboardEvent) {
    return listener.type === keyboardEvent.type && listener.altKey === keyboardEvent.altKey && listener.ctrlKey === keyboardEvent.ctrlKey && listener.metaKey === keyboardEvent.metaKey && listener.shiftKey === keyboardEvent.shiftKey;
  }
  function keyOrCodeMatches(listener, keyboardEvent) {
    if (!(keyboardEvent instanceof KeyboardEvent)) {
      return false;
    }
    if (listener.keyOrCode === ALL_SYMBOL) {
      return true;
    }
    return listener.keyOrCode === keyboardEvent.code || listener.keyOrCode === keyboardEvent.key;
  }
  function mouseButtonMatches(listener, mouseEvent) {
    if (!(mouseEvent instanceof MouseEvent)) {
      return false;
    }
    if (listener.keyOrCode === ALL_SYMBOL) {
      return true;
    }
    return listener.keyOrCode === (0, _getMouseName.default)(mouseEvent.button);
  }
  function specialCaseMatches(keyboardListener, keyboardEvent, platform) {
    if (onlyModifiers([], keyboardListener) && onlyModifiers(['shift'], keyboardEvent)) {
      return keyboardEvent.key === keyboardListener.keyOrCode;
    }
    if (onlyModifiers(['shift'], keyboardListener) && onlyModifiers(['shift'], keyboardEvent)) {
      return rootKeyForShiftKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    if (platform === 'Macintosh' && onlyModifiers(['alt'], keyboardListener) && onlyModifiers(['alt'], keyboardEvent)) {
      return rootKeyForMacAltKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    if (platform === 'Macintosh' && onlyModifiers(['shift', 'alt'], keyboardListener) && onlyModifiers(['shift', 'alt'], keyboardEvent)) {
      return rootKeyForMacShiftAltKey(keyboardEvent.key) === keyboardListener.keyOrCode;
    }
    return false;
  }
  const ALL_MODIFIERS_EXCEPT_CMD = _modifiersArray.default.filter(m => m != 'cmd');
  function onlyModifiers(names, obj) {
    for (let modifier of ALL_MODIFIERS_EXCEPT_CMD) {
      if (names.includes(modifier) && !obj[`${modifier}Key`]) {
        return false;
      }
      if (!names.includes(modifier) && obj[`${modifier}Key`]) {
        return false;
      }
    }
    return true;
  }
  function rootKeyForShiftKey(key) {
    return _keyMaps.SHIFT_KEY_MAP[key] || key;
  }
  function rootKeyForMacAltKey(key) {
    return _keyMaps.MAC_ALT_KEY_MAP[key] || key;
  }
  function rootKeyForMacShiftAltKey(key) {
    return _keyMaps.MAC_SHIFT_ALT_KEY_MAP[key] || key;
  }
});