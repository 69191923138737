define("ember-keyboard/utils/listener-name", ["exports", "ember-keyboard/utils/get-cmd-key"], function (_exports, _getCmdKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = listenerName;
  function sortedKeys(keyArray) {
    return keyArray.sort().join('+');
  }
  function listenerName(type) {
    let keyArrayOrString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    let keyArray = keyArrayOrString;
    if (typeof keyArrayOrString === 'string') {
      keyArray = keyArrayOrString.split('+');
    }
    if (keyArray.indexOf('cmd') > -1) {
      keyArray[keyArray.indexOf('cmd')] = (0, _getCmdKey.default)();
    }
    let keys = sortedKeys(keyArray || []);
    if (keys === '') {
      keys = '_all';
    }
    return `${type}:${keys}`;
  }
});