define("ember-modifier/-private/class/modifier-manager", ["exports", "@ember/modifier", "@ember/object", "@ember/destroyable", "ember-modifier/-private/compat"], function (_exports, _modifier, _object, _destroyable, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function destroyModifier(modifier) {
    modifier.willRemove();
    modifier.willDestroy();
  }
  class ClassBasedModifierManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)(true ? '3.22' : '3.13'));
      this.owner = owner;
    }
    createModifier(factoryOrClass, args) {
      const Modifier = (0, _compat.isFactory)(factoryOrClass) ? factoryOrClass.class : factoryOrClass;
      const modifier = new Modifier(this.owner, args);
      (0, _destroyable.registerDestructor)(modifier, destroyModifier);
      return modifier;
    }
    installModifier(instance, element, args) {
      instance.element = element;
      if (true) {
        (0, _compat.consumeArgs)(args);
      }
      instance.didReceiveArguments();
      instance.didInstall();
    }
    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      (0, _object.set)(instance, 'args', args);
      if (true) {
        (0, _compat.consumeArgs)(args);
      }
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }
    destroyModifier(instance) {
      (0, _destroyable.destroy)(instance);
    }
  }
  _exports.default = ClassBasedModifierManager;
});