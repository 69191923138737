define("ember-collapsible-panel/components/cp-panel/component", ["exports", "@ember/runloop", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/component", "ember-collapsible-panel/components/cp-panel/template"], function (_exports, _runloop, _object, _computed, _service, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let hasLiquidFireDep;
  {
    hasLiquidFireDep = false;
  }
  var _default = _component.default.extend({
    layout: _template.default,
    panelActions: (0, _service.inject)(),
    shouldAnimate: (0, _computed.and)('hasLiquidFireDep', 'animate'),
    disabled: false,
    hasLiquidFireDep,
    group: null,
    // passed in if rendered as part of a {{cp-panels}} group

    classNames: ['cp-Panel'],
    classNameBindings: ['isOpen:cp-is-open:cp-is-closed', 'disabled:cp-is-disabled'],
    // Caller can overwrite
    name: (0, _computed.oneWay)('elementId'),
    panelState: (0, _object.computed)('name', function () {
      const name = this.get('name');
      return this.get(`panelActions.state.${name}`);
    }),
    isOpen: (0, _computed.readOnly)('panelState.isOpen'),
    isClosed: (0, _computed.not)('isOpen'),
    panelsWrapper: null,
    animate: true,
    didReceiveAttrs() {
      this._super(...arguments);

      // If caller passes in open=, use it
      if (this.get('open') !== undefined) {
        this.set('panelState.boundOpenState', this.get('open'));
      }
    },
    // Register with parent panels component
    didInsertElement() {
      this._super(...arguments);
      (0, _runloop.scheduleOnce)('afterRender', () => {
        let group = this.get('group');
        if (group) {
          this.get('panelState').set('group', group);
        }
      });
    },
    // Custom action called when toggling that can be provided by caller
    didToggle() {},
    actions: {
      toggleIsOpen() {
        if (this.get("disabled")) {
          return;
        }
        let name = this.get('name');
        this.get('panelActions').toggle(name);
        this.didToggle(name);
      }
    }
  });
  _exports.default = _default;
});