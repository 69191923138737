define("ember-keyboard/fixtures/key-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SHIFT_KEY_MAP = _exports.MAC_SHIFT_ALT_KEY_MAP = _exports.MAC_ALT_KEY_MAP = void 0;
  const SHIFT_KEY_MAP = {
    A: 'a',
    B: 'b',
    C: 'c',
    D: 'd',
    E: 'e',
    F: 'f',
    G: 'g',
    H: 'h',
    I: 'i',
    J: 'j',
    K: 'k',
    L: 'l',
    M: 'm',
    N: 'n',
    O: 'o',
    P: 'p',
    Q: 'q',
    R: 'r',
    S: 's',
    T: 't',
    U: 'u',
    V: 'v',
    W: 'w',
    X: 'x',
    Y: 'y',
    Z: 'z',
    '!': '1',
    '@': '2',
    '#': '3',
    $: '4',
    '%': '5',
    '^': '6',
    '&': '7',
    '*': '8',
    '(': '9',
    ')': '0',
    _: '-',
    '+': '=',
    '<': ',',
    '>': '.',
    '?': '/',
    ':': ';',
    '"': "'",
    '~': '`',
    '{': '[',
    '}': ']',
    '|': '\\'
  };
  _exports.SHIFT_KEY_MAP = SHIFT_KEY_MAP;
  const MAC_ALT_KEY_MAP = {
    å: 'a',
    b: 'b',
    ç: 'c',
    '∂': 'd',
    // 'Dead': 'e',
    ƒ: 'f',
    '©': 'g',
    '˙': 'h',
    // 'Dead': 'i',
    '∆': 'j',
    '˚': 'k',
    '¬': 'l',
    µ: 'm',
    // 'Dead': 'n',
    ø: 'o',
    π: 'p',
    œ: 'q',
    '®': 'r',
    ß: 's',
    '†': 't',
    // 'Dead': 'u',
    '√': 'v',
    '∑': 'w',
    '≈': 'x',
    '¥': 'y',
    Ω: 'z',
    '¡': '1',
    '™': '2',
    '£': '3',
    '¢': '4',
    '∞': '5',
    '§': '6',
    '¶': '7',
    '•': '8',
    ª: '9',
    º: '0',
    '–': '-',
    '≠': '=',
    '≤': ',',
    '≥': '.',
    '÷': '/',
    '…': ';',
    æ: "'",
    // 'Dead': '`',
    '“': '[',
    '‘': ']',
    '«': '\\'
  };
  _exports.MAC_ALT_KEY_MAP = MAC_ALT_KEY_MAP;
  const MAC_SHIFT_ALT_KEY_MAP = {
    Å: 'a',
    ı: 'b',
    // 'Dead': 'c',
    Î: 'd',
    // 'Dead': 'e',
    Ï: 'f',
    '˝': 'g',
    Ó: 'h',
    ˆ: 'i',
    Ô: 'j',
    '': 'k',
    Ò: 'l',
    Â: 'm',
    '˜': 'n',
    Ø: 'o',
    // 'Dead': 'p',
    Œ: 'q',
    '‰': 'r',
    Í: 's',
    ˇ: 't',
    '¨': 'u',
    '◊': 'v',
    '„': 'w',
    '˛': 'x',
    Á: 'y',
    '¸': 'z',
    '⁄': '1',
    '€': '2',
    '‹': '3',
    '›': '4',
    ﬁ: '5',
    ﬂ: '6',
    '‡': '7',
    '°': '8',
    '·': '9',
    '‚': '0',
    '—': '-',
    '±': '=',
    '¯': ',',
    '˘': '.',
    '¿': '/',
    Ú: ';',
    Æ: "'",
    '`': '`',
    '”': '[',
    '’': ']',
    '»': '\\'
  };
  _exports.MAC_SHIFT_ALT_KEY_MAP = MAC_SHIFT_ALT_KEY_MAP;
});