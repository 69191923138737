define("ember-keyboard/utils/keyboard-listener", ["exports", "ember-keyboard/utils/platform"], function (_exports, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const ALT_REGEX = /^alt$/i;
  const SHIFT_REGEX = /^shift$/i;
  const CTRL_REGEX = /^ctrl$/i;
  const META_REGEX = /^meta$/i;
  const CMD_REGEX = /^cmd$/i;
  class KeyboardListener {
    // keydown, keyup, keypress

    constructor() {
      let platform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _platform.default)();
      _defineProperty(this, "type", void 0);
      _defineProperty(this, "altKey", false);
      _defineProperty(this, "ctrlKey", false);
      _defineProperty(this, "shiftKey", false);
      _defineProperty(this, "metaKey", false);
      _defineProperty(this, "keyOrCode", void 0);
      _defineProperty(this, "platform", void 0);
      this.platform = platform;
    }
    static parse(s) {
      let platform = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _platform.default)();
      let keyboardListener = new KeyboardListener(platform);
      let [eventType, keyCombo] = s.split(':');
      keyboardListener.type = eventType;
      if (keyCombo === '+') {
        keyboardListener.keyOrCode = keyCombo;
        return keyboardListener;
      }
      keyCombo.split('+').forEach(part => {
        if (ALT_REGEX.test(part)) {
          keyboardListener.altKey = true;
        } else if (CTRL_REGEX.test(part)) {
          keyboardListener.ctrlKey = true;
        } else if (META_REGEX.test(part)) {
          keyboardListener.metaKey = true;
        } else if (SHIFT_REGEX.test(part)) {
          keyboardListener.shiftKey = true;
        } else if (CMD_REGEX.test(part)) {
          if (platform.indexOf('Mac') > -1) {
            keyboardListener.metaKey = true;
          } else {
            keyboardListener.ctrlKey = true;
          }
        } else {
          keyboardListener.keyOrCode = part;
        }
      });
      return keyboardListener;
    }
    createMatchingKeyboardEvent() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new KeyboardEvent(this.type, Object.assign({
        // one of these next two will be incorrect. For test usage, if usually
        // doesn't matter, but you can pass in correct values via opts if needed.
        key: this.keyOrCode,
        code: this.keyOrCode,
        altKey: this.altKey,
        ctrlKey: this.ctrlKey,
        metaKey: this.metaKey,
        shiftKey: this.shiftKey
      }, opts));
    }
  }
  _exports.default = KeyboardListener;
});